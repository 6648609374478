import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import {Container, Row, Col, Button, Card, CardDeck, Jumbotron} from "react-bootstrap";
import { Link } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import fig1 from "../../assets/Home/Fig1.png"
import fig2 from "../../assets/Home/Fig2.png"
import fig3 from "../../assets/Home/Fig3.png"
import * as Icon from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet';

const Home = () => {    
    return (
        <Container className="home dark:bg-gray-900">
            <Container className = "home-top dark:text-gray-100">
            <Helmet>
                <title>Home | Global Credit Project</title>
                <meta name="description" content="Credit markets play a crucial role in the functioning of the economy, but they can also be a source of financial and economic crises. The Global Credit Project is dedicated to the construction, analysis, and dissemination of data on credit markets around the world. " />
            </Helmet>
                <h1 className="home-title text-3xl text-left mb-3">Global Credit Project</h1>
                <p className="text-base px-3 text-left border-l-2 border-blue-800">
                Credit markets play a crucial role in the functioning of the economy, but they can also be a source of financial and economic crises. The Global Credit Project is dedicated to 	the construction, analysis, and dissemination of data on credit markets around the world.
                <br/>
                <br/>
                The Global Credit Project provides the world's most comprehensive historical statistics on credit markets covering 120 advanced and emerging economies going back to 1940. Based on archival and manually collected data, we provide information on both aggregate private credit and disaggregated credit by both industry and by type of household credit. The underlying data are the result of an extensive data collection and harmonization effort over several years. We believe the data can be useful for both research and teaching. We provide historical data for free under a creative commons license.
                </p>

                <div>
                  <h5>Follow these links to learn more about the data: </h5>
                  <div className='grid grid-cols-1 md:grid-cols-2 justify-items-center'>
                    <Link type='button' to='/long-term-trend' className='rounded bg-gray-50 dark:bg-gray-100 dark:hover:bg-gray-300 border-1 no-underline m-2 b-1 w-3/4 px-2 py-2 text-base font-semibold text-gray-900 shadow-md ring-3 ring-gray-600 hover:bg-gray-100'>Long-term trends in private credit</Link>
                    <Link type='button' to='/case-studies' className='rounded bg-gray-50 dark:bg-gray-100 dark:hover:bg-gray-300 no-underline m-2 b-1 w-3/4 px-2 py-2 text-base font-semibold text-gray-900 shadow-md ring-3 ring-gray-600 hover:bg-gray-100'>Private credit during financial crises</Link>
                    <Link type='button' to='/research' className='rounded bg-gray-50 dark:bg-gray-100 dark:hover:bg-gray-300 no-underline m-2 b-1 w-3/4 px-2 py-2 text-base font-semibold text-gray-900 shadow-md ring-3 ring-gray-600 hover:bg-gray-100'>Research</Link>
                    <Link type='button' to='/data' className='rounded bg-gray-50 dark:bg-gray-100 dark:hover:bg-gray-300 no-underline m-2 b-1 w-3/4 px-2 py-2 text-base font-semibold text-gray-900 shadow-md ring-3 ring-gray-600 hover:bg-gray-100'>Download the data</Link>
                  </div>
                </div>
            </Container>





            <ul
          role="list"
          className="mx-auto px-2 mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          <li className='rounded-xl'>
            <img className="aspect-[3/2] w-full border-2 rounded object-fill" src={fig1} alt="" />
            <span className='text-sm font-semibold'>Worldwide Growth in Credit-to-GDP</span>
          </li>
          <li className='rounded-xl'>
            <img className="aspect-[3/2] w-full border-2 rounded object-fill" src={fig2} alt="" />
            <span className='text-sm font-semibold'>Credit Expansion during Major Credit Booms</span>
          </li>

          <li className='rounded-xl'>
            <img className="aspect-[3/2] w-full border-2 rounded object-fill" src={fig3} alt="" />
            <span className='text-sm font-semibold'>Credit Growth around Financial Crises</span>
          </li>


          </ul>


            {/* <Container className="home-graph-nav bg-blue-400 dark:bg-sky-500">
              <Container className="home-graph-nav-width">
                <Link to="/data" className = "nav-left">Data</Link>
                <Row className="nav-center">
                    <Col xs={2} md={3} lg={4}>
                      <a className="flex items-center text-gray-100 decoration-0 hover:text-gray-200" href = "#long-term-trend">
                        <Icon.GraphUp className="mr-5"/> 
                        <span>Long-term trends</span>
                      </a>
                    </Col>

                    <Col xs={2} md={3} lg={4}>
                      <a className="flex items-center text-gray-100 decoration-0 hover:text-gray-200" href="/case-studies#patterns-around-financial-crises">
                        <Icon.GraphUp className="mr-5"/> 
                        <span>Financial crisis case studies</span>
                      </a>
                    </Col>
                </Row>
                <Link to="/methodology" className = "nav-right">Research</Link>
              </Container>
            </Container> */}

                  
        </Container>
    ); 
}

export default Home



