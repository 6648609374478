import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState, useRef } from "react"
import {Container, OverlayTrigger, Tooltip as OTooltip, Row, Col, Button, Card, CardDeck, Jumbotron} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LongTermTrend.css";
// import rawData from "../Data/Full_df2.json"
// import rawData2 from "../Data/Full_df3.json"
// import rawData3 from "../Data/Full_df4.json"
// import DropdownSelection from "../../components/DropdownSelection/DropdownSelection.js"
import * as Icon from 'react-bootstrap-icons';
import {FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, OutlinedInput, Checkbox, ListItemText} from '@mui/material';
import { makeStyles, Theme, createStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { CSVLink } from 'react-csv'
import { AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label, Legend, ResponsiveContainer, Text } from 'recharts';

// import rawData11 from "../Data/Full_df11.json"
import rawDatarc from "../Data/Full_dfrc2.json"

import { keepTheme } from '../../Theme';
import { ClassNames } from '@emotion/react';

import CanvasJSReact from '../../assets/canvasjs.react';
import { line } from 'd3';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;


const { faker } = require('@faker-js/faker');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120,
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "blue"
    }
  },
  select: {
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--options-border)'
    },
    '& .MuiSvgIcon-root:hover:not(.Mui-disabled):before': {
        color: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline:hover:not(.Mui-disabled):before': {
      borderColor: 'white'
    },
  }
}));

const Graph1 = (props) => {
  const names = {
    'C': "Manufacturing",
    'F': "Construction",
    'FL': "Construction/real estate",
    "A": "Agriculture",
    'BC': "Manufacturing (incl. mining)",
    'I': "Hotels and restaurants",
    'G': "Retail/wholesale trade",
    'GI': "Retail/wholesale trade, hotels/restaurants",
    'L': 'Real estate',
    'HH': "Households",
    "K": "Financial corporations (excl. banks)",
    "NFC": "Non-financial corporations",
    'Total': "Total",
    'Sum of Others': "Sum of Others",
    "Corp": "Total firm credit",
    "adj_Z": "Other sectors",
    "adj_A": "Agriculture",
    'adj_BC': "Manufacturing (incl. mining)",
    'adj_GI': "Retail/wholesale trade, hotels/restaurants",
    'adj_FL': "Construction/real estate",
    "adj_HJ": "Transportation/storage + Information/communication"
  }

    const classes = useStyles();
    
    const [viz, setViz] = React.useState('line')
    
    const renderTooltip = (text) => (
      <OTooltip id="button-tooltip" style={{position:"fixed"}}>
        {text}
      </OTooltip>
    );

    const [country, setCountry] = React.useState("All Countries")
    const [sectors, setSectors] = React.useState(['Total'])

    var currStartDate = ''
    for (var i = 0; i < rawDatarc[country]['datasets'].length; i++) {
      if (currStartDate != '') {
        break
      }
      for (var j = 0; j < sectors.length; j++) {
        var sector = sectors[j]
        if (Object.keys(rawDatarc[country]['datasets'][i]).includes(sector)) {
          currStartDate = rawDatarc[country]['datasets'][i]['name']
          break
        }
      }
    }

    var currEndDate = ''
    for (var i = rawDatarc[country]['datasets'].length - 1; i >= 0; i--) {
      if (currEndDate != '') {
        break
      }
      for (var j = 0; j < sectors.length; j++) {
        var sector = sectors[j]
        if (Object.keys(rawDatarc[country]['datasets'][i]).includes(sector)) {
          currEndDate = rawDatarc[country]['datasets'][i]['name']
          break
        }
      }
    }

    const [selectedStartDate, setSelectedStartDate] = React.useState(currStartDate)
    const [selectedEndDate, setSelectedEndDate] = React.useState(currEndDate)
    const [labels, setLabels] = React.useState(rawDatarc[country]['labels'])
    const [graphType, setGraphType] = React.useState(0)

    const [togClass, setTogClass] = useState('light');
    let theme = localStorage.getItem('theme');

    let isOverview = country == "All Countries" || country == 'Emerging Markets' || country == 'Advanced Economies'



    var dataRc = JSON.parse(JSON.stringify(rawDatarc[country]['datasets'])
    ).filter((data) => {
      return data['name'] >= selectedStartDate && data['name'] <= selectedEndDate;
    })

    useEffect(() => {
      if (localStorage.getItem('theme') === 'theme-dark') {
          setTogClass('dark')
      } else if (localStorage.getItem('theme') === 'theme-light') {
          setTogClass('light')
      }
    }, [theme])

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: togClass == "light" ? "#000000" : "#ffffff"
          },
          title: {
            display: true,
            text: 'Sectors',
          },
        },
        title: {
          display: true,
          text: 'Type of Credit',
          color: togClass == "light" ? "#000000" : "#ffffff"
        },
      },
      scales: {
        yAxes:
        { 
          grid: {
            borderDash: [8, 4],
            color: "#79D7ED"
          },
          ticks:{
            beginAtZero: true,
            color: togClass == "light" ? "#000000" : "#ffffff"
          },
          title: {
            display: true,
            text: 'Credit / GDP',
          },
        },
        xAxes: 
        {
          grid: {
            borderDash: [8, 4],
            color: togClass == "light" ? "#E9E9E9" : "#5E4B56"
          },
          ticks:{
            beginAtZero: true,
            color: togClass == "light" ? "#000000" : "#ffffff"
          },
          title: {
            display: true,
            text: 'Year',
          },
        }
        
      }
    };

    const handleChangeCountry = (event: SelectChangeEvent) => {
      setCountry(event.target.value);
      var currCountry = event.target.value
      var currStartDate = ''
      for (var i = 0; i < rawDatarc[currCountry]['datasets'].length; i++) {
        if (Object.keys(rawDatarc[currCountry]['datasets'][i]).includes('Total')) {
          currStartDate = rawDatarc[currCountry]['datasets'][i]['name']
          break
        }
      }
  
      var currEndDate = ''
      for (var i = rawDatarc[currCountry]['datasets'].length - 1; i >= 0; i--) {
        if (Object.keys(rawDatarc[currCountry]['datasets'][i]).includes('Total')) {
          currEndDate = rawDatarc[currCountry]['datasets'][i]['name']
          break
        }
      }
      setSelectedStartDate(currStartDate);
      setSelectedEndDate(currEndDate)
      setLabels(rawDatarc[event.target.value]["labels"])
      setSectors(["Total"]);
      setGraphType(0);
      setViz('line')
      // if (event.target.value == "All Countries" || event.target.value == 'Emerging Markets' || event.target.value == 'Advanced Economies') {
      //   setSectors(['Corp', 'adj_A', 'adj_BC', 'adj_FL', 'adj_GI', 'adj_HJ', 'adj_Z']);
      // }
    };

    const reset = (event: SelectChangeEvent) => {
      let country = Object.keys(rawDatarc)[0]
      setCountry(country);
      setSectors(["Total"]);
      setSelectedStartDate(rawDatarc[country]['labels'][0]);
      setSelectedEndDate(rawDatarc[country]['labels'].at(-1))
      setLabels(rawDatarc[country]['labels'])
      setGraphType(0);
    };


    var unique_sectors = []
    for (var item of rawDatarc[country]['datasets']) {
      var sects = Object.keys(item)
      unique_sectors.push(...sects)
    }
    unique_sectors = [...new Set(unique_sectors)]
    unique_sectors = unique_sectors.filter(item => item !== "name" && item !== "label")

    const handleChangeSelection = (event : SelectChangeEvent) => {
      var curr_selection = event.target.value
      var currSectors = []
      setGraphType(curr_selection)
      if (curr_selection == 0) {
        setViz('line')
      }
      const sects1a = ['Corp', 'HH']
      const sects1b = ['NFC', 'K', 'HH']
      const HH_subs = unique_sectors.filter(function(x){return x.slice(0,3)==="HH_"})
      switch(curr_selection) {
        case 0:
          currSectors = ["Total"]
          break;
        case 1:
          if (unique_sectors.includes("K") && unique_sectors.includes("NFC")) {
            for (let sect of sects1b) {
              if (unique_sectors.includes(sect)) {
                currSectors.push(sect)
              }
            }
          } else {
              for (let sect of sects1a) {
                if (unique_sectors.includes(sect)) {
                  currSectors.push(sect)
                }
              } 
          }

          break;
        case 2:
          if (unique_sectors.includes("Corp")) {
            for (let sect of sects1a) {
              if (unique_sectors.includes(sect)) {
                currSectors.push(sect)
              }
            } 
          } else {
              for (let sect of sects1b) {
                if (unique_sectors.includes(sect)) {
                  currSectors.push(sect)
                }
            }
          }
          
          currSectors = currSectors.concat(HH_subs)

          break;
        case 3:
          let sects3 = ['Total', 'HH', "NFC", "Corp", "K"]

          currSectors = unique_sectors.filter(x => !sects3.includes(x) && !HH_subs.includes(x))

          if (unique_sectors.includes("K")) {
            currSectors.push("K")
          }
          break;
        case 4:
          let sects4 = ['Total', 'HH', "NFC", "Corp", "K"]
          currSectors = unique_sectors.filter(x => !sects4.includes(x))
          if (unique_sectors.includes("K")) {
            currSectors.push("K")
          }

          break;
      }
      setSectors(currSectors)

      var currStartDate = ''
      for (var i = 0; i < rawDatarc[country]['datasets'].length; i++) {
        if (currStartDate != '') {
          break
        }
        for (var j = 0; j < currSectors.length; j++) {
          var sector = currSectors[j]
          if (Object.keys(rawDatarc[country]['datasets'][i]).includes(sector)) {
            currStartDate = rawDatarc[country]['datasets'][i]['name']
            break
          }
        }
      }
  
      var currEndDate = ''
      for (var i = rawDatarc[country]['datasets'].length - 1; i >= 0; i--) {
        if (currEndDate != '') {
          break
        }
        for (var j = 0; j < currSectors.length; j++) {
          var sector = currSectors[j]
          if (Object.keys(rawDatarc[country]['datasets'][i]).includes(sector)) {
            currEndDate = rawDatarc[country]['datasets'][i]['name']
            break
          }
        }
      }

      setSelectedStartDate(currStartDate)
      setSelectedEndDate(currEndDate)
    };

    const handleChangeStartDate = (event: SelectChangeEvent) => {
      var curr_date = event.target.value
      setSelectedStartDate(event.target.value);
    };

    const handleChangeEndDate = (event: SelectChangeEvent) => {
      var curr_date = event.target.value
      setSelectedEndDate(event.target.value);
    };


    const countries = Object.keys(rawDatarc)

    let graphLabel = rawDatarc[country]["labels"].filter(function(date) {
      return date >= currStartDate && date <= currEndDate;
     })

      const lineColor = [
        "#1f77b4",  
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf",
        "#1f77b4",
        "#ff7f0e",
      ]

    let chart = useRef();

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="w-[150px] border border-black bg-white p-1 text-xs rounded ">
            {/* <p className="label">{`${label} : ${JSON.stringify(payload)}`}</p> */}
            <p className="p-0 m-0 font-semibold dark:text-black">{label}</p>
            {payload.map((sect, index) => (<div className='p-0' style={{color:sect['stroke']}}>{names[sect["name"]]}: {sect['value']}</div>))}
          </div>
        );
      }
    
      return null;
    };

  // let maxData = dataRc.slice(-1)[0]['Total'] != null ? dataRc.slice(-1)[0]['Total'] : dataRc.slice(-1)[0]['Corp']
  let maxData = Math.max(...Object.values(dataRc.slice(-1)[0]).filter(x => x != null))

  const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return toPercent(ratio, 2);
  };

    const PercentTooltip = (o) => {
      const { payload, label } = o;
      const total = payload.reduce((result, entry) => result + entry.value, 0);
    
      return (
        <div className="w-[150px] border border-black bg-white p-1 text-xs rounded">
          <p className="p-0 m-0 font-semibold">{`${label} (Total: ${total})`}</p>
          <ul className="p-0">
            {payload.map((entry, index) => (
              <li key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
              </li>
            ))}
          </ul>
        </div>
      );
    };
    
    return (
            <Container id = "long-term-trend" className = "home-section">

              <Container className = 'home-section-intro dark:text-gray-100'>
              <h1 className="home-section-title">Explore the data</h1>
              <p className="home-section-para">
              You can explore long-term trends in the composition of credit to the private sector globally or by country below. 
			  You can adjust the graph with the drop-down menus. All numbers are outstanding amounts scaled over GDP.
			  Choose between a simple line chart, a stacked line chart, or an area chart showing shares of credit.
              </p>
              </Container>

              <Container className = 'home-section-options dark:text-gray-100'>
              <h1 className="home-section-options-title">Graph options
              <span onClick = {() => {reset()}} className="reset-button">Reset</span>
              </h1>


              <Container className = "options">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                className={classes.select}
                  labelId="country-label"
                  id="country"
                  value={country}
                  label="Country"
                  onChange={handleChangeCountry}
                  autoWidth
                >
                  <MenuItem value="All Countries">All Countries</MenuItem>
                  <MenuItem value="Advanced Economies">Advanced Economies</MenuItem>
                  <MenuItem value="Emerging Markets">Emerging Markets</MenuItem>
                  {countries.sort()
                    .filter(country => country != 'All Countries' && country != 'Advanced Economies' && country != 'Emerging Markets')
                    .map((country) => {return <MenuItem value={country}>{country}</MenuItem>})}

                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="type-label">Selection</InputLabel>
                <Select
                className={classes.select}
                  labelId="type-label"
                  id="label"
                  value={graphType}
                  label="Selection"
                  onChange={handleChangeSelection}
                  autoWidth
                >
                  {/* <MenuItem value={0}>Customize</MenuItem> */}
                  <MenuItem value={0}>Total credit</MenuItem>
                  <MenuItem value={1}>Household vs. firm Credit</MenuItem>
                  {/* <MenuItem value={2}>Household credit detail</MenuItem> */}
                  { unique_sectors.some(sect => ['A', 'BC', 'FL', 'GI', 'Sum of Others'].indexOf(sect) >= 0) &&
                    <MenuItem value={3}>Firm credit detail</MenuItem>
                  }
                  {/* <MenuItem value={4}>All detail</MenuItem> */}
                </Select>
              </FormControl>
              

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="start-date-label">From</InputLabel>
                <Select
                className={classes.select}
                  labelId="start-date-label"
                  id="start-date"
                  value={selectedStartDate}
                  label="From"
                  onChange={handleChangeStartDate}
                  autoWidth
                >
                  {graphLabel.map(date => {
                    return (<MenuItem value={date}>{date}</MenuItem>)
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="end-date-label">To</InputLabel>
                <Select
                  className={classes.select}
                  labelId="end-date-label"
                  id="end-date"
                  value={selectedEndDate}
                  label="To"
                  onChange={handleChangeEndDate}
                  autoWidth
                >
                  {graphLabel.map(date => {
                    return (<MenuItem value={date}>{date}</MenuItem>)
                  })}
                </Select>
              </FormControl>


            { graphType != 0 &&
            <Stack className="right-viz" direction="row" spacing={1}>
              <OverlayTrigger 
                  placement="top" 
                  delay={{ show: 10, hide: 10 }} 
                  overlay={renderTooltip("Line Graph")}>
                <IconButton aria-label="delete"color="primary" onClick = {() => {setViz("line")}}>
                  <Icon.GraphUp className="home-nav-icon"/> 
                </IconButton>
              </OverlayTrigger>

              <OverlayTrigger 
                  placement="top" 
                  delay={{ show: 10, hide: 10 }} 
                  overlay={renderTooltip("Area Chart")}>
                <IconButton color="secondary" onClick = {() => {setViz("area")}}>
                  <Icon.BarChartFill className="home-nav-icon" /> 
                </IconButton>
              </OverlayTrigger>

              <OverlayTrigger 
                  placement="top" 
                  delay={{ show: 10, hide: 10 }} 
                  overlay={renderTooltip("Area Chart")}>
                <IconButton aria-label="delete"color="secondary" onClick = {() => {setViz("share")}}>
                  <Icon.FileBarGraphFill className="home-nav-icon"/> 
                </IconButton>
              </OverlayTrigger>
            </Stack>
            }

            </Container>
            </Container>
            
            <div className="w-full h-[450px] mb-20">
            <div className="w-full text-md font-semibold dark:text-gray-100"> {country} </div>
            <ResponsiveContainer width="100%" height={450}>
              {viz === "line" ?
              <LineChart
                data={dataRc}
              >
                {/* <text x={500} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="14">{country}</tspan>
                </text> */}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" fontSize={15}>
                  <Label value="Year" fontSize={20} offset={-10} position="insideBottom" />
                </XAxis>
                <YAxis tickCount={8} fontSize={15}>
                  <Label value="Credit / GDP" fontSize={20} offset={maxData > 1000000 ? -35 : -10} angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Legend wrapperStyle={{fontSize: "16px"}} formatter={(value, entry, index) => <span className="text-gray-800 dark:text-white">{names[value] ? names[value] : value}</span>} verticalAlign="top" height={50} />
                {/* <Line type="monotone" dataKey="A" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Total" stroke="#82ca9d" /> */}
                {sectors.map((sector, index) => {
                  return (<Line type="monotone" dataKey={sector} 
                  stroke={lineColor[index]}
                  strokeWidth={2}
                  animationDuration={500} 
                  dot={false} />)
                })}
              </LineChart> 
                : viz === "area" ?
                <AreaChart
                  data={dataRc}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" fontSize={15}>
                    <Label value="Year" fontSize={20} offset={-10} position="insideBottom" />
                  </XAxis>
                  <YAxis tickCount={8} fontSize={15}>
                    <Label value="Credit / GDP" fontSize={20} dy={95} offset={maxData > 1000000 ? -35 : -10} angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                  </YAxis>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend wrapperStyle={{fontSize: "16px"}} formatter={(value, entry, index) => <span className="text-gray-800 dark:text-white">{names[value] ? names[value] : value}</span>} verticalAlign="top" height={36} />
                  {sectors.map((sector, index) => {
                    return (<Area type="monotone" stackId='1' dataKey={sector} 
                    stroke={lineColor[index]}
                    fill={lineColor[index]}
                    strokeWidth={2}
                    animationDuration={500} />)
                  })}
              </AreaChart> :
              <AreaChart
              data={dataRc}
              stackOffset="expand"
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" fontSize={15}>
                <Label value="Year" fontSize={20} offset={-10} position="insideBottom" />
              </XAxis>
              <YAxis tickCount={8} fontSize={15}>
                <Label value="Share of Total Credit" fontSize={20} dy={95} offset={-5} angle={-90} position="insideLeft" />
              </YAxis>
              <Tooltip content={<PercentTooltip />} />
              <Legend wrapperStyle={{fontSize: "16px"}} formatter={(value, entry, index) => <span className="text-gray-800 dark:text-white">{names[value] ? names[value] : value}</span>} verticalAlign="top" height={36} />
              {sectors.map((sector, index) => {
                return (<Area type="monotone" dataKey={sector} 
                stackId='1'
                stroke={lineColor[index]}
                fill={lineColor[index]}
                strokeWidth={2}
                animationDuration={500} />)
              })}
              </AreaChart>
            }
            </ResponsiveContainer>
            </div>

            </Container>


    ); 
}

export default Graph1



