import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
// import {Nav, Navbar, NavDropdown, Col, Row, Container} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Toggle from '../../Toggle.js';
import { BrightnessHighFill as SunIcon, MoonStarsFill as MoonIcon} from 'react-bootstrap-icons';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

const Header = (props) => {

  const location = useLocation();
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

    return (
          // {/* <Navbar className="navBar" expand="lg" variant="dark">

          // <Navbar.Brand as={Link} to="/honme">Global Credit Project</Navbar.Brand>
          // <Navbar.Toggle aria-controls="basic-navbar-nav" />

          // <Navbar.Collapse id="basic-navbar-nav">
          //     <Nav className="mr-auto">
          //       <Nav.Link as={Link} to="/home">Home</Nav.Link>
          //       <Nav.Link as={Link} to="/data">Data</Nav.Link>
          //       <Nav.Link as={Link} to="/methodology">Research</Nav.Link>
          //       <Nav.Link as={Link} to="/team">About us</Nav.Link>
          //     </Nav>
          // </Navbar.Collapse>

          // <Toggle togClass={this.props.togClass} setTogClass={this.props.setTogClass}/>
          // </Navbar> */}
          <Disclosure as="nav" className="bg-gray-100 border-gray-200 px-2 w-full border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="container mx-auto flex flex-nowrap items-center justify-between">

                  <div className="flex text-black">
                    <span className="self-center text-sm sm:text-xl text-inherit font-semibold whitespace-nowrap dark:text-white">The Global Credit Project</span>
                  </div>

                 <div className="hidden lg:flex justify-between items-center w-full md:w-auto">
                  <ul className="flex-col md:flex-row flex md:space-x-8 mt-3 md:mt-0 md:text-sm md:font-medium">
                    <li>
                      <Link to="/home" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                        location.pathname=='/home' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")} aria-current="page">Home</Link>
                    </li>
                    <li>
                      <Link to="/long-term-trend" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                        location.pathname=='/long-term-trend' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")} aria-current="page">Long-Term Trends</Link>
                    </li>
                    <li>
                      <Link to="/case-studies" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                        location.pathname=='/case-studies' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>Financial Crisis Case Studies</Link>
                    </li>
                    <li>
                      <Link to="/data" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                        location.pathname=='/data' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>Data</Link>
                    </li>
                    <li>
                      <Link to="/research" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                        location.pathname=='/research' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>Research</Link>
                    </li>
                    <li>
                      <Link to="/people" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                        location.pathname=='/people' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>About Us</Link>
                    </li>
                  </ul>
                  </div>

                  <div className="hidden lg:flex">
                    <Toggle togClass={props.togClass} setTogClass={props.setTogClass}/>
                  </div>
                  
                  <div className="-mr-2 flex items-center lg:hidden">
                    <div className="mr-2 sm:mr-6">
                      <Toggle togClass={props.togClass} setTogClass={props.setTogClass}/>
                    </div>
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
    
              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 pb-3 pt-2 [&>*]:no-underline">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <Disclosure.Button as={Link} to="/home" className={classNames("text-xl hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                      location.pathname=='/home' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")} aria-current="page">
                    Home
                  </Disclosure.Button>
                  <Disclosure.Button as={Link} to="/long-term-trend" className={classNames("text-xl hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                                      location.pathname=='/long-term-trend' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")} aria-current="page">
                    Long-Term Trends
                  </Disclosure.Button>
                  <Disclosure.Button as={Link} to="/case-studies" className={classNames("text-xl hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                        location.pathname=='/case-studies' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>
                    Financial Crisis Case Studies
                  </Disclosure.Button>
                  <Disclosure.Button as={Link} to="/data" className={classNames("text-xl hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                        location.pathname=='/data' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>
                    Data
                  </Disclosure.Button>
                  <Disclosure.Button as={Link} to="/research" className={classNames("text-xl hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                        location.pathname=='/research' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>
                    Research
                  </Disclosure.Button>
                  <Disclosure.Button as={Link} to="/people" className={classNames("text-xl    bvhover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
                        location.pathname=='/people' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>
                    About Us
                  </Disclosure.Button>
                  
                </div>

              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

    // <nav className="bg-gray-100 border-gray-200 px-2 w-full border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-800">
    //   <div className="container mx-auto flex flex-wrap items-center justify-between">
    //     <div className="flex text-black">
    //         <span className="self-center text-xl text-inherit font-semibold whitespace-nowrap dark:text-white">The Global Credit Project</span>
    //     </div>
    //     <div className="flex md:order-2">
    //       <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
    //         <span className="sr-only">Open main menu</span>
    //         {open ? (
    //           <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
    //         ) : (
    //           <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
    //         )}
    //       </Disclosure.Button>
    //     </div>
    //     <div className="hidden md:flex justify-between items-center w-full md:w-auto md:order-1" id="mobile-menu-4">
    //       <ul className="flex-col md:flex-row flex md:space-x-8 mt-3 md:mt-0 md:text-sm md:font-medium">
    //         <li>
    //           <Link to="/home" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
    //                             location.pathname=='/home' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")} aria-current="page">Home</Link>
    //         </li>
    //         <li>
    //           <Link to="/long-term-trend" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
    //                             location.pathname=='/long-term-trend' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")} aria-current="page">Long-Term Trends</Link>
    //         </li>
    //         <li>
    //           <Link to="/case-studies" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
    //                             location.pathname=='/case-studies' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>Financial Crisis Case Studies</Link>
    //         </li>
    //         <li>
    //           <Link to="/data" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
    //                             location.pathname=='/data' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>Data</Link>
    //         </li>
    //         <li>
    //           <Link to="/research" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
    //                             location.pathname=='/research' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>Research</Link>
    //         </li>
    //         <li>
    //           <Link to="/people" className={classNames("hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 active:text-blue-200",
    //                             location.pathname=='/people' ? "text-blue dark:text-white" : "text-gray-700 hover:text-blue")}>About Us</Link>
    //         </li>
    //       </ul>
    //       <Toggle togClass={props.togClass} setTogClass={props.setTogClass}/>
    //     </div>
    //   </div>

    // </nav>
    );
}

export default Header;