import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Switch, Route, useLocation} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import Home from "./pages/Home/Home.js"
import Data from "./pages/Data/Data.js"
import Research from "./pages/Research/Research.js"
import People from "./pages/People/People.js"
import CaseStudy from "./pages/CaseStudy/CaseStudy.js"
import LongTermTrend from './pages/LongTermTrend/LongTermTrend';
import License from './pages/License/License'
import { keepTheme, setTheme } from './Theme.js';


import Toggle from './Toggle.js'

function ScrollRestoration() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const [currentTime, setCurrentTime] = useState(0);
  const [togClass, setTogClass] = useState('light');



  useEffect(() => {
    keepTheme();
  }, []);

  return (
    <div className={"App " + togClass}>

      <header className="App-header dark:text-gray-800">
        <BrowserRouter>
        <Header togClass={togClass} setTogClass={setTogClass}></Header>
        <ScrollRestoration />

          {/* <div>
            <Link className="App-link" to="/">Home</Link>
            &nbsp;|&nbsp;
            <Link className="App-link" to="/page2">Page2</Link>
          </div> */}

          <Switch>                
            <Route path='/home' component={Home}/>  
            <Route path='/data' component={Data}/>  
            <Route path='/research' component={Research}/>               
            <Route path='/people' component={People}/>    
            <Route path='/case-studies' component={CaseStudy}/>              
            <Route path='/long-term-trend' component={LongTermTrend}/>   
            <Route path='/license' component={License}/>   
            <Route path='/' component={Home}/>            

            {/* <Route exact path="/">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                  Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                  className="App-link"
                  href="https://reactjs.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn React
                </a>
                <p style={{color:"black"}}>The current time is {currentTime}.</p>
                
            </Route>
            <Route path="/page2">
                <p>This is page 2!</p>
            </Route> */}
          </Switch>
          <Footer></Footer>
        </BrowserRouter>
      </header>

    </div>
  );
}

export default App;
