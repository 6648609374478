import React from 'react';
import ReactDOM from 'react-dom';
import { Fragment, useEffect, useState } from "react"
import {Container, Row, Col, Button, Card, CardDeck, Jumbotron} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CaseStudy.css";
// import DropdownSelection from "../../components/DropdownSelection/DropdownSelection.js"
import * as Icon from 'react-bootstrap-icons';
import {FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, OutlinedInput, Checkbox, ListItemText} from '@mui/material';
import { makeStyles, Theme, createStyles } from "@mui/styles";
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import rawData from "./case_study.json"

import {
  Chart,
  Filler,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { stack } from 'd3';
const { faker } = require('@faker-js/faker');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const labels = ['-5', '-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5'];

var data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

const Graph4 = (props) => {
  const [togClass, setTogClass] = useState('light');
  let theme = localStorage.getItem('theme');
  useEffect(() => {
    if (localStorage.getItem('theme') === 'theme-dark') {
        setTogClass('dark')
    } else if (localStorage.getItem('theme') === 'theme-light') {
        setTogClass('light')
    }
  }, [theme])

  let plugins = [
    {
      afterDraw: (chart) => {
        // eslint-disable-next-line no-underscore-dangle
        if (chart.tooltip._active && chart.tooltip._active.length) {
          // find coordinates of tooltip
          const activePoint = chart.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.element;
          console.log(chart.scales)
          const topY = chart.scales.yAxes.top;
          const bottomY = chart.scales.yAxes.bottom;

          // draw vertical line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'lightgray'
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  function customRadius( context )
    {
      let index = context.dataIndex;
      const year = crisis.slice(-4)
      const years = rawData[crisis.substring(0,3) + year]['labels']
      const idx = years.indexOf(Number(year)) 
      return index === idx ?
            10 :
            2;
    }
  
  const options = {
    maintainAspectRatio : false,
    elements: {
      point: {
        radius: customRadius,
        hoverRadius: 5,
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: togClass == "light" ? "#000000" : "#ffffff"
        }
      },
      title: {
        display: true,
        text: 'Type of Credit',
        color: togClass == "light" ? "#000000" : "#ffffff"
      },
      tooltip: {
        mode: 'index',
      },
    },

    scales: {
      yAxes:
      {
        grid: {
          color: "#79D7ED"
        },
        ticks:{
          beginAtZero: true,
          color: togClass == "light" ? "#000000" : "#ffffff",
        },
        // stacked: true
        title: {
          display: true,
          text: 'Credit-to-GDP (index = 100)',
          font: {
            size: 16
          }
        }
      },
      xAxes: 
      {
        grid: {
          color: togClass == "light" ? "#E9E9E9" : "#5E4B56"
        },
        ticks:{
          beginAtZero: true,
          color: togClass == "light" ? "#000000" : "#ffffff"
        },
        title: {
          display: true,
          text: 'Year',
          font: {
            size: 16
          }
        },
        stacked: true
      }
      
    }
  };  

  const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
      minWidth: 120,
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "blue"
      }
    },
    select: {
      color: 'white',
      '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--options-border)'
      },
      '& .MuiSvgIcon-root:hover:not(.Mui-disabled):before': {
          color: 'white'
      },
      '& .MuiOutlinedInput-notchedOutline:hover:not(.Mui-disabled):before': {
        borderColor: 'white'
      },
    }
  }));

  const classes = useStyles();
  const [crisis, setCrisis] = useState(rawData['selections'][0])

  const onChange = (event) => {

    setCrisis(event.target.value);
    console.log(crisis.substring(0,4))
    console.log(crisis.slice(-4))
  }

  const text = rawData[crisis.substring(0,3) + crisis.slice(-4)]['text'] ? rawData[crisis.substring(0,3) + crisis.slice(-4)]['text'].split('\n').map(str => <p>{str}</p>) : '';
       
  const [open, setOpen] = useState(false)
    return (

      <Container id = "patterns-around-financial-crises" className = "home-section">
      <Container className = 'home-section-intro dark:text-gray-100'>
        <h1 className="home-section-title">Credit growth around financial crises</h1>
        <p className="home-section-para">
        Explore where credit flows around episodes of systemic banking crises on average or by individual episode. 
		All figures show the ratio of sectoral credit-to-GDP (indexed to 100 in a focal year) around the onset of a 
		financial crisis episodes.
        </p>

        <div className='w-full flex justify-start flex-wrap'>
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="type-label">Crisis</InputLabel>
            <Select
            className={classes.select}
              labelId="type-label"
              id="label"
              value={crisis}
              label="Crisis"
              onChange={(event) => onChange(event)}
              autoWidth
            >
              {/* <MenuItem value={0}>Customize</MenuItem> */}
              {rawData['selections'].sort((a,b) => {return a.substring(4) > b.substring(4) ? 1 : -1}).map((item, index) => {
                return <MenuItem value={item}>{item.substring(4)}</MenuItem>
              })}
            </Select>
          </FormControl>
          <div className='flex flex-column items-center justify-center text-base'>
            <a className='hover:text-blue-700 hover:cursor-pointer' href='#desc'>
              Historical background on the crisis
            </a>
          </div>
        </div>
        {/* <Bar options={options} data={data} />
        <Bar options={options} data={sampleData} /> */}
        <div id="canvas-container">
        <Line options={options} data={rawData[crisis.substring(0,3)+crisis.slice(-4)]} plugins={plugins}/>
        </div>

        {/* <Line options={options} data={rawData[crisis.substring(0,4)]} /> */}
        <div className="mt-5 text-left text-lg" id='desc'>
          {text}
        </div>
      </Container>

      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
          <Popup open={open} setOpen={setOpen} text={text}/>
      </Dialog>
    </Transition.Root>
    

      </Container>

    ); 
}

export default Graph4

const Popup = (props) => {
  const { open, setOpen, text } = props;

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="w-1/2 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div>
              <div className="mt-2 mb-5 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-md font-semibold leading-6 text-gray-900">
                  Historical background of the crisis
                </Dialog.Title>
                <div className="mt-4">
                  {text}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 flex justify-center">
              <button
                type="button"
                className="inline-flex w-50 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => setOpen(false)}
              >
                Back
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  )
}


