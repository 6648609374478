import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import {Container, Row, Col, Button, Card, CardDeck, Jumbotron} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Icon from 'react-bootstrap-icons';
import "./People.css";

import Karsten_pfp from '../../assets/Team New/Karsten.png'
import Emil_pfp from '../../assets/Team New/Emil_Verner.png'
import Chang_pfp from '../../assets/Team New/Chang_Liu.png'

import Jason_pfp from '../../assets/Team New/Jason_Ng.png'
import Ziyu_pfp from '../../assets/Team New/Su_Ziyu.png'
import Xiangyu_pfp from '../../assets/Team New/Qiu_Xiangyu.png'
import { Helmet } from 'react-helmet';


const People = () => {
    return (
        <Container className="team flex items-center justify-center dark:bg-gray-900">
            <Helmet>
                <title>About Us | Global Credit Project</title>
            </Helmet>

            <section>
                <div className="py-6 px-4 mx-auto max-w-screen-xl text-center lg:py-10 lg:px-6">
                    <div className="mx-auto mb-4 max-w-screen-sm lg:mb-16">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">About us</h2>
                        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">The Global Credit Project is based on academic work by researchers at MIT Sloan and the National University of Singapore.</p>
                    </div> 

                    <h2 className="mb-8 text-4xl tracking-tight font-semibold text-gray-900 dark:text-white">Researchers</h2>
                    <div className="w-3/4 grid gap-2 mx-auto lg:gap-4 justify-items-center sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                        <Person name='Karsten Müller' title='Assistant Professor of Finance' org='National University of Singapore' img={Karsten_pfp} link='https://www.karstenmueller.com' social={[]}/>
                        <Person name='Emil Verner' title='Associate Professor of Finance' org='MIT Sloan' img={Emil_pfp} link='https://www.emilverner.com' social={[]}/>
                    </div>

                    <h2 className="mt-4 mb-8 text-4xl tracking-tight font-semibold text-gray-900 dark:text-white">Research Assistants</h2>
                    <div className="grid gap-8 lg:gap-16 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3">                        
                        <Person name='Jason Ng' title='Research Assistant' org='National University of Singapore' img={Jason_pfp} social={[]}/>
                        <Person name='Ziyu Su' title='Research Assistant' org='National University of Singapore' img={Ziyu_pfp} social={[]}/>
                        <Person name='Qiu Xiangyu' title='Research Assistant' org='National University of Singapore' img={Xiangyu_pfp} social={[]}/>
                    </div>
                </div>
                <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Alumni</h2>
                    <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">The Global Credit Project was supported by many outstanding research assistants, without whom our research would not have been possible.</p>
                </div> 
                <ul role="list" className="grid pl-0 mx-5 lg:grid-cols-5 gap-x-12 gap-y-12 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 mb-16">
                    <Alumni name='Wei Chin Ho' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Yuxuan Tang' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Adamson Bryant' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Brendan Tan' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Mengrui Jiang' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Julien Maire' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Yifei Zou' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Michelle Girouard' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Sarah Guo' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Nils Hübel' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Gudrun Müller' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Sungho Park' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Yash Roy' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Flemming Slok' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                    <Alumni name='Aissata Thiam' role='' img="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png" social={[]}/>
                </ul>
            </section>
        </Container>
    ); 
}

function Person(props) {
    // let isAlumni = false;
    // let withLink = false;
    // if (props.alumni) {
    //     isAlumni = true;
    // }
    // let twitter = props.twitter
    // let personal = props.personal
    // let linkedin = props.linkedin
    const name = props.name
    const title = props.title
    const image = props.img
    const social = props.social
    const link = props.link ? props.link : "#"
    const org = props.org ? props.org : ""

    return (
        <div className="text-center text-gray-500 dark:text-gray-400">
            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={image} alt="Bonnie Avatar" />
            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {link === '#' ? 
                <div>{name}</div>
                : <a href={link} target='_blank' rel="noreferrer">{name}</a>
                }   
            </h3>
            {title !== "Research Assistant" && <p className='text-lg mb-0 font-semibold'>{title}</p>}
            <p className='text-sm'>{org}</p>
            {/* <ul className="flex pl-0 justify-center mt-4 space-x-4">
                <li>
                    <a href="#" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                    </a>
                </li>
                <li>
                    <a href="#" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                    </a>
                </li>
                <li>
                    <a href="#" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
                    </a>
                </li>
                <li>
                    <a href="#" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
                    </a> 
                </li> 
            </ul> */}
        </div>
    )
}


const Alumni = (props) => {
    const person = {
        name: props.name,
        role: props.role,
        img: props.img
    }
    return (
        <li key={person.name} className='flex justify-start'>
            <div className="flex items-center justify-center gap-x-4">
            {/* <img className="h-16 w-16 rounded-full" src={person.img} alt="" /> */}
            <span
            className='text-gray-400 text-lg border-blue-200 border-2 border-gray-600 bg-blue-100 hover:border-indigo-600 hover:text-indigo-600 rounded-full h-10 w-10 flex items-center justify-center'
            >
            {person.name[0].toUpperCase()}
            </span>
            <div>
                <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900 dark:text-gray-400">{person.name}</h3>
                {/* <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p> */}
            </div>
            </div>
        </li>
    )
}

export default People



