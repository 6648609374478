import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import {Container, Row, Col, Button, Card, CardDeck, Jumbotron} from "react-bootstrap";
import { Link } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css";
import "./LongTermTrend.css";
import Graph1 from "./Graph1.js"
import * as Icon from 'react-bootstrap-icons';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { Helmet } from 'react-helmet';

const LongTermTrend = () => {    
    return (
        <Container className="home dark:bg-gray-900">
            <Container className = "home-top dark:text-gray-100">
            <Helmet>
                <title>Long-Term Trends | Global Credit Project</title>
            </Helmet>
                <h1 className="home-title text-3xl text-left mb-3">Long-term trends in global credit markets</h1>
                <p className="text-base px-3 text-left border-l-2 border-blue-800">

				The Global Credit Project provides the world's most comprehensive historical statistics on credit markets 
                covering 120 advanced and emerging economies going back to 1940. Based on archival and manually collected data, 
				we provide information on outstanding credit by industry and type of household credit. Visit the research page for more details.
                <br/>
                <br/>
                Several key insights emerge from the data:
				<ol>
                    <li className='flex flex-row items-center'><Icon.Icon1CircleFill className="mt-1 mr-3 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />Credit as a share of the economy has risen sharply over the past five decades.</li>
                    <li className='flex flex-row items-center'><Icon.Icon2CircleFill className="mt-1 mr-3 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />Household debt has boomed globally, while firm credit has remained stable as a share of GDP.</li>
                    <li className='flex flex-row items-center'><Icon.Icon3CircleFill className="mt-1 mr-3 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />Firm credit has shifted from tradable sectors to construction, real estate, and other non-tradable sectors.</li>
                </ol>
                </p>
            </Container>

            <Graph1 />

                  
        </Container>
    ); 
}

export default LongTermTrend



