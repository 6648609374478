import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import {Container, Row, Col, Button, Card, CardDeck, Jumbotron} from "react-bootstrap";
import { Link } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css";
import "./CaseStudy.css";
import Graph from "./CaseStudyGraph.js"
import * as Icon from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet';

const CaseStudy = () => {   

    return (
        <Container className="home dark:bg-gray-900">
            <Helmet>
                <title>Financial Crisis Case Studies | Global Credit Project</title>
            </Helmet>
            <Container className = "home-top dark:text-gray-100">
                <h1 className="home-title text-3xl text-left mb-3">What happens in credit markets before systemic financial crises?</h1>
                <p className="text-base px-3 text-left border-l-2 border-blue-800">
                Financial crises are often credit booms gone wrong. But what happens during credit expansions that end in crises?
				Our data allows a comprehensive look at the composition of credit in the run-up to many important crisis episodes.
				While their origins may differ, our takeaway is that there are also important commonalities to these events. 
				Explore the data below to see for yourself. Each crisis comes with a narrative account of what happened before, 
				during, and after the start of the episode.

                </p>
            </Container>

            <Graph />



                  
        </Container>
    ); 
}

export default CaseStudy



